<template>
  <div class="homepage">
    <v-container>
      <v-row>
        <v-col class="d-flex justify-center pt-16" cols="12">
          <span class="uppercase secondary--text bold">Sezioni</span>
        </v-col>
        <v-col class="d-flex justify-center pt-8" cols="12">
          <homePageButton to="/listUtenti">CLIENTI</homePageButton>
        </v-col>
        <v-col class="d-flex justify-center pt-16" cols="12">
          <homePageButton to="/listAttivita">ATTIVITÀ</homePageButton>
        </v-col>
        <v-col class="d-flex justify-center pt-16" cols="12">
          <homePageButton to="/listDocumenti">DOCUMENTI</homePageButton>
        </v-col>
        <v-col class="d-flex justify-center pt-16" cols="12">
          <homePageButton to="/listSegnalazioni">SEGNALAZIONI</homePageButton>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import homePageButton from '@/components/HomePage_Button'

export default {
  name: 'HomePage',
  components: {
    homePageButton
  },
  methods: {
  },
}
</script>
